import ViewLoading from '@/components/print/defaults/ViewLoading'

export default {
  components: {ViewLoading},
  mounted() {
    document.body.classList.add('print-default')
  },
  destroyed() {
    document.body.classList.remove('print-default')
  },
  props: [
    'title',
    'subtitle',
    'loadingTitle',
    'isLoading',
    'orientation'
  ]
}