<script>
export default {
  name: 'ViewLoading',
  props: {
    title: {
      type: String,
      default: 'Aguarde'
    }
  },
  data () {
    return {}
  }
}
</script>

<template>
  <div class="loader">
    <div class="text-center"><i class="fa fa-spin fa-spinner"></i></div>
    {{ title }}
  </div>
</template>
