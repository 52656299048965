<template>
  <div class="simulador-folha-a4" :class="css">
    <view-loading :title="loadingTitle || null" v-if="isLoading"></view-loading>
    <transition name="slide">
      <div v-if="!isLoading" class="content-transition" id="container-printer">
        <div class="print-view-folha-a4">
          <slot/>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import PrintDefault from '@/components/print/defaults/PrintDefault'

export default {
  name: "PrintFolhaA4",
  mixins: [PrintDefault],
  mounted() {
    this.landscape()
  },
  beforeDestroy() {
    this.landscape(false)
  },
  data() {
    return {}
  },
  computed: {
    css () {
      let css = []
      if (this.isLoading) {
        css.push('is-loading')
      }
      if (this.orientation && this.orientation === 'landscape') {
        css.push('orientation-landscape')
      }
      return css
    }
  },
  methods: {
    landscape (enable = true) {
      if (this.orientation !== 'landscape') return
      if (!this.sheet) {
        this.sheet = document.createElement('style')
      }
      this.sheet.innerHTML = "@page { size: landscape !important; }"
      if (enable) {
        document.body.appendChild(this.sheet)
      } else {
        const sheetParent = this.sheet.parentNode;
        sheetParent.removeChild(this.sheet);
      }
    }
  }
}
</script>
