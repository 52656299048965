<script>
import {VueMaskDirective as mask} from 'v-mask'
import PrintA4 from "@/components/print/defaults/PrintA4"
import Highcharts from 'highcharts'
import Annotation from 'highcharts/modules/annotations'

Annotation(Highcharts)

export default {
  components: {PrintA4},
  nome: 'RelatoriosPrintViewRemocaoEntradaSaida',
  props: {
    data: {required: true}
  },
  directives: {mask},
  data() {
    return {
      isLoading: false
    }
  },
  computed: {},
  mounted() {
    this.$nextTick(() => {
      let title = 'Análise de Entrada e Saída de Bens'
      let series = {
        entradas: {
          name: 'Entradas',
          data: []
        },
        saidas: {
          name: 'Saídas',
          data: []
        }
      }
      let mountedSeries = []
      let categories = []
      /**
       * [{
          name: 'Installation',
          data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]
        }]
       */
      let result = this.data.result
      if (result && Object.keys(result).length) {
        Object.keys(result).map(k => {
          let item = result[k]
          // if (typeof series[k] === 'undefined') {
            /*series[k] = {
              name: null,
              data: []
            }*/
            let categorie
            if (this.data.separarPor === 'dia') {
              let dia = String(item.traducaoData['diaPtbr']).split('-')
              dia = dia[0]
              categorie = dia + ', ' + this.$options.filters.formatDate(item.data.date, 'dd/MM')
            } else if (this.data.separarPor === 'ano') {
              categorie = item.traducaoData['ano']
            } else {
              categorie = item.traducaoData['mesPtbr'] + ', ' + item.traducaoData['ano']
            }
            if (!categories.includes(categorie)) {
              categories.push(categorie)
            }
            series.entradas.data.push(item.entradas)
            series.saidas.data.push(item.saidas)
          // }

        })
        Object.keys(series).map(k => {
          mountedSeries.push(series[k])
        })
      }
      Highcharts.setOptions({
        lang: {
          thousandsSep: '.'
        }
      })

      Highcharts.chart('container-chart', {

        title: {
          text: title
        },

        subtitle: {
          text: 'Source: BI SL'
        },

        yAxis: {
          title: {
            text: 'Número de Entradas / Saídas'
          }
        },

        xAxis: {
          accessibility: {
            // rangeDescription: 'Range: 2010 to 2017'
          },
          categories: categories
        },

        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle'
        },

        plotOptions: {
          line: {
            dataLabels: {
              enabled: true
            },
            enableMouseTracking: false
          }
        },

        series: mountedSeries,

        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
              }
            }
          }]
        }

      })
    })
  },
  activated() {
  },
  created() {
  },
  deactivated() {
  },
  watch: {},
  methods: {}
}
</script>

<template>
  <print-a4 :is-loading="isLoading">
    <div v-if="data && data.template" v-html="data.template"></div>
  </print-a4>
</template>
